import Claim from './Claim'

import './styles/home.scss';

interface HomeProps {
    name: string,
}

function Home(props: HomeProps) {
    return (
        <div className="home">
            <header className="home-header">
                <div className="home-headline">{props.name}</div>
            </header>
            <div>
                <Claim />
            </div>
        </div>
    );
}

export default Home;
